import React from "react"
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"
import posed from "react-pose"
import closeSVG from "../images/close.svg"
import Layout from "../components/layout"
import styles from "../styles/books.module.css"
import Book from "../components/book"

const Box = posed.div({
  closed: { y: "300px" },
  open: { y: "0px", transition: { duration: 600 } },
})

const Back = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
  press: {
    scale: 1.1,
  },
})

export default ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Gaston Hummel</title>
    </Helmet>
    <Box className="container" initialPose="closed" pose="open">
      <div className="headerOther">
        <Back>
          <Link to="/">
            <img src={closeSVG} alt="back" height="32" width="32" />
          </Link>
        </Back>
        <div>
          <h2 className="center">Books</h2>
          <p className="center">
            Here I share insights from some of my favourite books.
          </p>
        </div>
      </div>
      <div className={styles.summaries}>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Book
            key={node.id}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            image={node.frontmatter.image}
            amazonLink={node.frontmatter.amazonLink}
            excerpt={node.excerpt}
            link={node.fields.slug}
          ></Book>
        ))}
      </div>
    </Box>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { type: { eq: "books" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            image
            amazonLink
          }
          fields {
            slug
          }
          excerpt(pruneLength: 280)
          html
        }
      }
    }
  }
`
