import React from "react"
import { Link } from "gatsby"
import posed from "react-pose"
import styles from "../styles/book.module.css"

const BookImage = posed.img({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
  press: {
    scale: 0.95,
  },
})

class Book extends React.Component {
  render() {
    return (
      <div className={styles.summary}>
        <a href={this.props.amazonLink} target="_new">
          <div className={styles.picture}>
            <BookImage
              className={styles.bookImage}
              src={this.props.image}
              alt="Icon"
              height="150"
            />
          </div>
        </a>
        <Link to={this.props.link}>
          <div className={styles.info}>
            <h4>{this.props.title}</h4>
            <div
              className={styles.desc}
              dangerouslySetInnerHTML={{ __html: this.props.excerpt }}
            />
          </div>
        </Link>
      </div>
    )
  }
}

export default Book
